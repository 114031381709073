import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import navigation from '@pedidosya/web-native-bridges/navigation';
import { fetchUser } from '@app/services/loyalty/fetchUser';
import { fetchSubscription } from '@app/services/loyalty/fetchSubscription';
import { fetchStatus } from '@app/services/loyalty/fetchStatus';
import { acceptTermsAndConditions } from '../../services/loyalty/acceptTermsAndConditions';
import Spinner from '../../components/Spinner';
import { useHeaderConfiguration } from '../../hooks/useHeaderConfiguration';
import queryKeyBuilders from '@commons/queries/keyBuilders';
import { getHomeLink } from '@commons/utils/deeplinks';
import { Congrats } from './components/congrats';
import { Congrats as RevampCongrats } from './components/revamp/congrats';
import { useCongratsPageTracker } from './tracking/CongratsPageTracker';
import { ROUTES } from '@commons/services/routes.service';
import { BrandError } from '../../components/Error';
import { PRESENTATION_QUERY } from '@commons/models/presentationQueryEnum';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';
import { useDeviceInfo } from '@app/contexts/deviceInfo';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useFlagRef, useFlagVariation } from '@app/contexts/FwfFlags/hooks';

const clearHistory = () => window?.HistoryInterface?.clearHistory();

export const CongratsPage = () => {
  const history = useHistory();
  const deviceInfo = useDeviceInfo();
  const subscriptionQuery = useQuery(queryKeyBuilders.subscription(), () => fetchSubscription());
  const userQuery = useQuery(queryKeyBuilders.user(), () => fetchUser());
  const queryClient = useQueryClient();
  const deprecateLandingLegacyFlag = useFlagVariation(
    BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_DEPRECATE_LANDING_LEGACY.KEY,
  );

  const { data, isLoading, isError } = userQuery;
  const { presentation = PRESENTATION_QUERY.ROOT, origin, isRevamp } = usePublicEnv();

  const BANNER_ORIGIN = 'banner';
  const HOME_ORIGIN = 'home';
  const ORIGIN_WHITELIST = [BANNER_ORIGIN, HOME_ORIGIN];
  useCongratsPageTracker({ isErrorPage: isError, isPageReady: !isLoading });

  useHeaderConfiguration();

  useEffect(() => {
    clearHistory();
  }, []);

  const backFlag = useFlagRef(BOOLEAN_FLAGS.WEB_LOYALTY_PROGRAM_CONGRATS_BACK.KEY);

  const isLandingOrWhitelist =
    presentation !== PRESENTATION_QUERY.MODAL || ORIGIN_WHITELIST.includes(origin);

  const canGoBack = () => {
    const legacyLandingFlow = !isLandingOrWhitelist && backFlag?.current;
    const deprecateLandingFlow = backFlag?.current;

    return deprecateLandingLegacyFlag ? deprecateLandingFlow : legacyLandingFlow;
  };

  const onGoBack = () => {
    if (deprecateLandingLegacyFlag) {
      const url = getHomeLink(deviceInfo);
      const replace = !deviceInfo.isWebview();
      if (canGoBack()) navigation.goBack();
      else if (replace) {
        history.replace(url, null);
      } else {
        window.location.assign(url);
      }
      return;
    }

    const url = isLandingOrWhitelist ? ROUTES.STATUS : getHomeLink(deviceInfo);
    const replace = isLandingOrWhitelist ? true : !deviceInfo.isWebview();

    /**
     * If it's known in advance that the user will be redirected to status-page using client-side navigation,
     * the app can start pre-fetching the data for the status while the user waits to be redirected. This
     * results in a smother transition
     */
    if (replace && url === ROUTES.STATUS) {
      queryClient.prefetchQuery({ queryKey: queryKeyBuilders.status(), queryFn: fetchStatus });
    }

    if (canGoBack()) navigation.goBack();
    else if (replace) {
      history.replace(url, null);
    } else {
      window.location.assign(url);
    }
  };

  /**
   * Send user terms and conditions acceptance, we do not care about the response
   * but only sending the request
   */
  useEffect(() => {
    if (subscriptionQuery?.data?.plan_id) {
      acceptTermsAndConditions({ planId: subscriptionQuery.data.plan_id }).then(() => {});
    }
  }, [subscriptionQuery?.data?.plan_id]);

  // Publish event announcing the susbscription status has changed
  useEffect(() => {
    import('@pedidosya/web-native-bridges/eventbus').then((eventbus) => {
      eventbus.publish('plusSubscriptionChanged', {});
    });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const legacyOnCloseCondition = isLandingOrWhitelist ? null : onGoBack;
  const legacyOnGoBackCondition = isLandingOrWhitelist ? onGoBack : null;

  if (isError) {
    return (
      <BrandError
        primaryActionLabel="Intentar nuevamente"
        primaryActionClick={userQuery.refetch}
        onClose={deprecateLandingLegacyFlag ? onGoBack : legacyOnCloseCondition}
        onGoBack={!deprecateLandingLegacyFlag && legacyOnGoBackCondition}
      />
    );
  }

  return isRevamp ? (
    <RevampCongrats user={data} onGoBack={onGoBack} />
  ) : (
    <Congrats user={data} onGoBack={onGoBack} />
  );
};
