import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { CongratsBottomSheet } from '../congratsBottomSheet';
import { LottieContainer, CongratsPageContainer } from '../styled-components';
import plusDingDong from './Plus-DingDong-bg.json';
import BottomSheet from '@pedidosya/web-fenix/system/BottomSheet';

const TRANSITION_DURATION = 300;
const SHOW_FADEOUT_CONGRATS_TIME = 3800;
const SHOW_CONGRATS_DURATION_TIME = 4000;

export const Congrats = ({ user, onGoBack }) => {
  const [hideCongrats, setHideCongrats] = useState(false);
  const { origin } = usePublicEnv();

  // Schedule congrats close
  useEffect(() => {
    setTimeout(() => {
      setHideCongrats(true);
    }, SHOW_FADEOUT_CONGRATS_TIME);
  }, []);

  // Schedule page redirection
  useEffect(() => {
    setTimeout(() => {
      onGoBack();
    }, SHOW_CONGRATS_DURATION_TIME);
    // Lint rule is disabled as this function is meant to run only at mount time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin]);

  return (
    <>
      <CongratsPageContainer animationDuration={TRANSITION_DURATION} hideCongrats={hideCongrats}>
        <LottieContainer>
          <Lottie animationData={plusDingDong} loop={false} />
        </LottieContainer>
        <BottomSheet open={!hideCongrats} handler={false} scrollEnabled={false} variant="dual">
          <CongratsBottomSheet user={user} />
        </BottomSheet>
      </CongratsPageContainer>
    </>
  );
};

Congrats.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
  }),
};
