import styled, { keyframes, css } from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import RevampTypography from '@pedidosya/web-fenix/foundations/Typography';

export const bannerSlideInUp = keyframes`
  from {
    margin-bottom: -100%;
  }

  to {
    margin-bottom: 0%;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const LottieContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`;

export const CongratsBottomSheetContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 99;
  width: 100%;
  animation: ${bannerSlideInUp} ${({ animationDuration }) => animationDuration}ms
    cubic-bezier(0, 0, 0, 1) ${({ animationDelay }) => animationDelay || 0}ms;
  animation-fill-mode: both;
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
`;

export const BottomSheetTitle = styled(Typography)`
  text-align: center;
  margin: ${({ theme }) => `${theme.space('spacing-12')} ${theme.space('spacing-24')}`};
`;

export const BottomSheetSubtitle = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space('spacing-16')};
`;

export const CongratsPageContainer = styled.div`
  ${({ hideCongrats, animationDuration }) =>
    hideCongrats &&
    css`
      animation: ${animationDuration || 300}ms ${fadeOut} ease-out;
      animation-fill-mode: both;
    `}
`;
